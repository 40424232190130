'use client';

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Modal, Table } from 'react-bootstrap';
import {
  HiArrowsUpDown,
  HiArrowUpTray,
  HiChevronDown,
  HiChevronUp,
  HiCog6Tooth,
  HiPencil,
  HiPlusCircle,
  HiTrash,
} from 'react-icons/hi2';
import { IoIosSettings } from "react-icons/io";
import { toast } from 'react-toastify'; // Import toast
import "./GridTable.css";
import EditColumnClassifier from '../../modals/EditColumnClassifier';


export default function GridTable({
  initialColumns = [],
  initialAttributes = [],
  initialData = [],
  pushColumn,
  showSampleData,
  deleteColumn,
  editColumn,
  addRow,
  deleteRow,
  updateRow
}) {
  const [data, setData] = useState({
    columns: [],
    attributes: [],
    data: []
  });
  const [columns, setColumns] = useState([])
  const [sorting, setSorting] = useState([]);
  const [connectedClassifier ,setConnectedClassifier]=useState([])
  const [fileName ,setFileName]=useState('')
  const [selectedColumn ,setSelectedColumn]=useState('')
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [newRowData, setNewRowData] = useState([]);
  const [showNewRowModal, setShowNewRowModal] = useState(false);
  const [editingColumnIndex, setEditingColumnIndex] = useState(null);
  const [newColumnIndex, setNewColumnIndex] = useState(null);
  const [editedColumn, setEditedColumn] = useState(null);
  const [showAddColumnModal, setShowAddColumnModal] = useState(false);
  const [newColumnHeader, setNewColumnHeaders] = useState([])
  const [newColumnData, setNewColumnData] = useState({
    name: '',
    datatype: 'text',
    description: '',
    unique: false,
    mandatory: false,
    computeField: false,
    search:false
  });
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [addColumnPosition, setAddColumnPosition] = useState(null); // Added state for column add position
  const [recordToDelete, setRecordToDelete] = useState(null); // Optional: To track the record being deleted
  const [recordToDeleteIsSample, setRecordToDeleteIsSample] = useState(null); // Optional: To track the record being deleted
  const [isSample ,setIsSample]=useState("")

  const [editingRowIndex, setEditingRowIndex] = useState(null); // Added state for row editing


  useEffect(() => {
    if (data?.columns?.length) {
      setNewRowData(data.columns.map(() => ''))

      const newColumns = [
        //checkbox
        // {
        //   id: 'select',
        //   header: ({ table }) => (
        //     <Form.Check
        //     className='checBoxRowSelectAll'
        //       type="checkbox"
        //       checked={table.getIsAllPageRowsSelected()}
        //       onChange={(e) => table.toggleAllPageRowsSelected(e.target.checked)}
        //       aria-label="Select all"
        //     />
        //   ),
        //   cell: ({ row }) => (
        //     <Form.Check
        //     className='checBoxRow'
        //       type="checkbox"
        //       checked={row.getIsSelected()}
        //       onChange={(e) => row.toggleSelected(e.target.checked)}
        //       aria-label="Select row"
        //     />
        //   ),
        //   enableSorting: false,
        //   enableHiding: false,
        // },
        ...data.columns.map((column, index) => ({
          accessorFn: (row) => row[index],
          id: column || `column_${index}`,
          header: column || `Column ${index}`,
          cell: (info) => info.getValue(),
        })),
        {
          id: 'actions',
          // header: 'Actions',
          cell: ({ row }) => (
            <div className="d-flex flex-row gap-2">
              {/* {console.log(row,"rowrowrowrowrow")} */}
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() =>{ handleEditRow(row.index);
                  console.log(row.original.isSample,"row.original.isSample")

                  setIsSample(row.original.isSample)

                }}
              >
                <HiPencil />
              </Button>
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => {
                  console.log(row.original.isSample,"row.original.isSample")
                  setShowDeleteConfirmModal(true);
                  setRecordToDelete( [row.index]   );
                  setRecordToDeleteIsSample(row.original)
                
                }}
              >
                <HiTrash />
              </Button>
            </div>
          ),
        },
      ];
      setColumns(newColumns)
    }
  }, [data])


// console.log(recordToDeleteIsSample,"recordToDeleteIsSample")

  useEffect(() => {
    setData({
      columns:
        initialColumns?.length >= 2
          ? initialColumns
          : ['FileName', 'Date/Time', ...initialColumns],
      attributes:
        initialAttributes.length >= 2
          ? initialAttributes
          : [null, null, ...initialAttributes],
      data: initialData,
    });
    setNewRowData(
      (initialColumns.length >= 2
        ? initialColumns
        : ['FileName', 'Date/Time', ...initialColumns]
      ).map(() => ''),
    );
  }, [initialColumns, initialAttributes, initialData]);

  const addColumn = async () => {
    if (newColumnData.name && !data.columns.includes(newColumnData.name)) {
      const currentIndex = newColumnIndex;
      const newColumnIdx =
        addColumnPosition === 'left' ? currentIndex : currentIndex + 1;
  
      const newColumns = [
        ...data.columns.slice(0, newColumnIdx),
        newColumnData.name,
        ...data.columns.slice(newColumnIdx),
      ];
  
      const newAttributes = [
        ...data.attributes.slice(0, newColumnIdx),
        {
          _id: Math.random().toString(36).substr(2, 9),
          title: newColumnData.name,
          description: newColumnData.description,
          unique: newColumnData.unique,
          mandatory: newColumnData.mandatory,
          computeField: newColumnData.computeField,
          datatype: newColumnData.datatype,
          search: newColumnData.search,
        },
        ...data.attributes.slice(newColumnIdx),
      ];
  
      const newData = data.data.map((row) => {
        const rowArray = Object.values(row);
        return [
          ...rowArray.slice(0, newColumnIdx),
          '',
          ...rowArray.slice(newColumnIdx),
        ];
      });
  
      setData({
        columns: newColumns,
        attributes: newAttributes,
        data: newData,
      });
  
      setNewColumnData({
        name: '',
        datatype: 'text',
        description: '',
        unique: false,
        mandatory: false,
        computeField: false,
        search: false,
      });
  
      setShowAddColumnModal(false);
  
      await pushColumn({
        title: newColumnData.name,
        description: newColumnData.description,
        unique: newColumnData.unique,
        mandatory: newColumnData.mandatory,
        computeField: newColumnData.computeField,
        search: newColumnData.search,
        datatype: newColumnData.datatype,
        appendColumn: addColumnPosition === 'right',
        columnName: data.columns[currentIndex],
      });
    } else if (data.columns.includes(newColumnData.name)) {
      toast.warning(
        'A column with this name already exists. Please choose a different name.',
      );
    }
  };
  const handleDeleteColumn = async (index) => {
    if (index < 2) {
      toast.warning('The first two columns cannot be deleted.');
      return;
    }
    await deleteColumn(data.columns[index]);
    const newColumns = data.columns.filter(
      (col) => col !== data.columns[index],
    );
    const newAttributes = data.attributes?.filter(
      (attr = {}) => {
        return attr?.title && attr.title !== data.columns[index]
      }
    );
    const newData = data.data.map((row) => row.filter((_, i) => i !== index));
    setData({ columns: newColumns, attributes: newAttributes, data: newData });
    setEditingColumnIndex(null);
  };

    const addRowData = () => {
      setData((prevData) => ({
        ...prevData,
        data: [...prevData.data, newRowData],
      }));
      setNewRowData(data.columns.map(() => ''));
      setShowNewRowModal(false);
    };

  const deleteRows = async (rowIndexes, isSample = false) => {
    try {
      rowIndexes?.forEach(async (index) => {
        const isDeleted = deleteRow(null, index, isSample, index ,recordToDeleteIsSample)
        if (isDeleted) {
          const newData = data.data.filter((_, index) => !rowIndexes.includes(index));
          setData((prevData) => ({
            ...prevData,
            data: newData,
          }));
          setRowSelection({});
        }
      })
    } catch (error) {
      console.log('handleDeleteRow error: ', error);
    }
  };

  const updateColumn = async (index, updatedColumn) => {
    if (index < 2) {
      toast.warning('The first two columns cannot be modified.');
      return;
    }
    if (
      updatedColumn.name !== data.columns[index] &&
      data.columns.includes(updatedColumn.name)
    ) {
      toast.warning(
        'A column with this name already exists. Please choose a different name.',
      );
      return;
    }

    const newColumns = [...data.columns];
    newColumns[index] = updatedColumn.name;
    const newAttributes = [...data.attributes];
    newAttributes[index] = {
      ...newAttributes[index],
      title: updatedColumn.name,
      description: updatedColumn.description,
      unique: updatedColumn.unique,
      mandatory: updatedColumn.mandatory,
      search: updatedColumn.search,
      computeField: updatedColumn.computeField,
      datatype: updatedColumn.datatype,
    };
    setData((prevData) => ({
      ...prevData,
      columns: newColumns,
      attributes: newAttributes,
    }));
    await editColumn({
      columnName: data.columns[index],
      title: updatedColumn.name,
      description: updatedColumn.description,
      unique: updatedColumn.unique,
      mandatory: updatedColumn.mandatory,
      search: updatedColumn.search,
      connectedClassifier:connectedClassifier,
      connectedClassifierField:selectedColumn,
      connectedClassifierFileName:fileName,
      header:updatedColumn.header,
      computeField: updatedColumn.computeField,
      datatype: updatedColumn.datatype,
    });

    setEditingColumnIndex(null);
    setEditedColumn(null);
  };
  const table = useReactTable({
    data: data.data,
    columns,
    state: {
      sorting,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row, index) => index,
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const lines = content.split('\n');
        const headers = lines[0].split(',').map((header) => header.trim());
        const values = lines
          .slice(1)
          .map((line) => line.split(',').map((value) => value.trim()));
        const newAttributes = headers.map(() => null);
        setData({ columns: headers, attributes: newAttributes, data: values });
      };
      reader.readAsText(file);
    }
  };

  const handleEditRow = (rowIndex) => {
    console.log('handleEditrow')
    setEditingRowIndex(rowIndex);
    console.log(rowIndex, data.data,"testastehjfasfjafsajkfasjkfaeyeehhjehjhjehje")
    if (Array.isArray(data.data[rowIndex])) {
      setNewRowData([...data.data[rowIndex]]);
    } else {
      setNewRowData({ ...data.data[rowIndex] });
      console.error('The row is not iterable:', data.data[rowIndex]);
    }
    setShowNewRowModal(true);
  };
  const addOrUpdateRow = () => {
    if (editingRowIndex !== null) {
      // edit row
      console.log(data.data,"newData")
      const newData = [...data.data];
      const columns =[...data.columns];
      newData[editingRowIndex] = newRowData;
      const newData2 = newData.map(row => {
        let rowObject = {};
        columns.forEach((col, index) => {
          rowObject[col] = row[index];
        });
        console.log("rowObject=>",rowObject)
        return rowObject;
      });
      setData((prevData) => ({
        ...prevData,
        data: newData,
      }));
      console.log(isSample,"isSample")
      updateRow(newData2, editingRowIndex ,isSample)
      setEditingRowIndex(null);
    } 
    else {
      // add new row
      addRow(newRowData);
    }
    setNewRowData(data.columns.map(() => ''));
    setShowNewRowModal(false);
  };

  const addNewColumnWithOptions = () => {
    setShowAddColumnModal(true)
    setAddColumnPosition('right'); // Default to 'right' when opening the modal
    const currentHeaders = table.getHeaderGroups() || [];
    const { headers = [] } = currentHeaders[0] || {};

    const headersGroup = headers.reduce((acc, header) => {
      const { id = '', index = 0 } = header || {};
      if (id !== 'select' && id !== 'actions') {
        acc.push({
          id,
          index
        })
      }
      return acc;
    }, []) || []
    // const newColumnData={
      
    //   data: Array(data.data.length).fill("")
    // }
    
    // console.log("headersgroup=>",headersGroup)
    // console.log("columnsssssss=>",columns)
    
    
    setNewColumnHeaders(headersGroup);
    // setColumns=((prevColumns) => [...prevColumns, newColumnData.columnName]);

    // setData((prevData)=>({
    //   ...prevData,
    //   data: prevData.data.map(row => [...row, ""]) 
    // }))
    // setNewColumnData({
    //   data: Array(data.data.length).fill("")
    // })
    // console.log( "newColumnData=>",newColumnData)

    // console.log("data2222",data);
    
  

    // setData((prevData,newColumnIdx) => ({
    //   ...prevData,
    //   data: prevData.data.map((row) => {
    //     console.log("newColumnIdx =>",newColumnIdx)
    //     const newRow = [...row];
    //     newRow.splice(newColumnIdx, 0, ""); 
    //     setNewRowData(newRow)
    //     return newRow;
    //   })
    // }));
  }
  console.log(editedColumn,'editedColumn')

  const chooseColumnNameForNewColumn = (e) => {
    const { target: { value } } = e;
    if (!value) return
    setNewColumnIndex(data.columns.indexOf(value))
    // setEditingColumnIndex(data.columns.indexOf(value))
    setNewColumnData((prev) => ({
      ...prev,
      columnName: value
    }))
  }

  return (
    <div className="container-fluid p-4">
      <div className="mb-4 d-flex align-items-center gap-2">
        {/* <Form.Control
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          className="d-none"
          id="csv-upload"
        /> */}
        {/* <label htmlFor="csv-upload">
          <Button
            variant="outline-primary"
            className="d-flex align-items-center"
          >
            <HiArrowUpTray className="me-2" />
            Import CSV
          </Button>
        </label> */}
        {/* {header.id !== 'select' &&
          header.id !== 'actions' && (
            <Dropdown>
              {index !== 0 && (
                <Dropdown.Toggle
                  as="div"
                  id={`dropdown-${header.id}`}
                  style={{ display: 'inline-block', border: 'none', background: 'none' }}
                >
                  <img
                    style={{
                      marginLeft: '8px',
                      width: '18px',
                      cursor: 'pointer',
                    }}
                    className="dotsIcon"
                    src={settings}
                    alt="settings"
                  />
                </Dropdown.Toggle>
              )}
              <Dropdown.Menu>
                {index > 1 && (
                  <Dropdown.Item
                    onClick={() => {
                      setEditingColumnIndex(data.columns.indexOf(header.id));
                      setAddColumnPosition('left');
                      setShowAddColumnModal(true);
                    }}
                  >
                    <HiPlusCircle className="me-2" />
                    Add Column to Left
                  </Dropdown.Item>
                )}
                {index >= 1 && (
                  <Dropdown.Item
                    onClick={() => {
                      setEditingColumnIndex(data.columns.indexOf(header.id));
                      setAddColumnPosition('right');
                      setShowAddColumnModal(true);
                    }}
                  >
                    <HiPlusCircle className="me-2" />
                    Add Column to Right
                  </Dropdown.Item>
                )}
                {index > 1 && (
                  <Dropdown.Item
                    onClick={() => {
                      const index = data.columns.indexOf(header.id);
                      setEditingColumnIndex(index);
                      setEditedColumn({
                        name: data.columns[index],
                        ...data.attributes[index],
                      });
                    }}
                  >
                    <HiPencil className="me-2" />
                    Edit Column
                  </Dropdown.Item>
                )}
                {data.columns.indexOf(header.id) >= 2 && (
                  <Dropdown.Item
                    onClick={() =>
                      handleDeleteColumn(data.columns.indexOf(header.id))
                    }
                  >
                    <HiTrash className="me-2" />
                    Delete Column
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>


          )} */}
        <Button
          variant="outline-success"
          onClick={() => addNewColumnWithOptions()}
          className="d-flex align-items-center"
        >
          <IoIosSettings className="me-2" />
          Add Column
        </Button>
        <Button
          variant="outline-success"
          onClick={() => setShowNewRowModal(true)}
          className="d-flex align-items-center"
        >
          <HiPlusCircle className="me-2" />
          Add Row
        </Button>
        {/* <Button
          variant="outline-danger"
          onClick={() => deleteRows(Object.keys(rowSelection).map(Number))}
          className="d-flex align-items-center"
          disabled={Object.keys(rowSelection).length === 0}
        >
          <HiTrash className="me-2" />
          Delete Selected Rows
        </Button> */}
      </div>
      <div className="table-responsive">
        <Table striped bordered hover className="shadow-sm">
          <thead className='thead'>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => {
                  return (
                    <th key={header.id} className="px-3 py-2 bg-light">
                      {header.isPlaceholder ? null : (
                        <div>
                          <div className="headerItemTop d-flex justify-content-between align-items-center">
                            <div
                              className={
                                header.column.getCanSort()
                                  ? 'cursor-pointer user-select-none'
                                  : ''
                              }
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              <div className="headerText d-flex align-items-center">
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                                {header.column.getCanSort() && (
                                  <span className="ms-2">
                                    {header.column.getIsSorted() === 'asc' ? (
                                      <HiChevronUp />
                                    ) : header.column.getIsSorted() ===
                                      'desc' ? (
                                      <HiChevronDown />
                                    ) : (
                                      <HiArrowsUpDown />
                                    )}
                                  </span>
                                )}
                              </div>
                            </div>
                            {header.id !== 'select' &&
                              header.id !== 'actions' &&
                              index > 1 && (
                                <Dropdown   style={{ marginLeft: "3px" }}>
                                  {/* <Dropdown.Toggle
                                    variant="outline-secondary"
                                    size="sm"
                                    id={`dropdown-${header.id}`}
                                  > */}
                                    {/* <HiCog6Tooth 
                                    variant="outline-secondary"
                                    // size="md"
                                    id={`dropdown-${header.id}`}/> */}
                                  {/* </Dropdown.Toggle> */}

                                  <Dropdown.Toggle
                                    variant="outline-secondary"
                                    size="sm"
                                    as="div" 
                                    id={`dropdown-${header.id}`}
                                    style={{ display: 'inline-block', border: 'none', background: 'none' }}
                                  >
                                    <HiCog6Tooth />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {index >= 2 && (
                                      <Dropdown.Item 
                                        onClick={() => {
                                          const index = data.columns.indexOf(
                                            header.id,
                                          );
                                          setEditingColumnIndex(index);
                                          setEditedColumn({
                                            name: data.columns[index],
                                            ...data.attributes[index],
                                          });
                                        }}
                                      >
                                        <HiPencil className="me-2" />
                                        Edit Column
                                      </Dropdown.Item>
                                    )}
                                    {data.columns.indexOf(header.id) >= 2 && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleDeleteColumn(data.columns.indexOf(header.id))
                                        
                                        }
                                      >
                                        <HiTrash className="me-2" />
                                        Delete Column
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div className="mt-2">
                              <Form.Control
                                size="sm"
                                placeholder={`Filter ${header.column.columnDef.header}`}
                                value={header.column.getFilterValue() ?? ''}
                                onChange={(e) =>
                                  header.column.setFilterValue(e.target.value)
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} className= {row.original.isSample?'isSample tbodyText': "tbodyText"}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className= {row.original.isSample?'isSample tbodyText': "tbodyText"}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {table.getRowModel().rows.length > 0 && (
  <div className="d-flex justify-content-between align-items-center mt-4">
    <div className="d-flex align-items-center gap-2">
      <Button
        variant="outline-primary"
        size="sm"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        Previous
      </Button>
      <Button
        variant="outline-primary"
        size="sm"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        Next
      </Button>
    </div>
    <span className="d-flex align-items-center gap-1">
      <div>Page</div>
      <strong>
        {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
      </strong>
    </span>
  </div>
)}



{/* Delete Modal */}
<Modal
  show={showDeleteConfirmModal}
  onHide={() => setShowDeleteConfirmModal(false)}
>
  <Modal.Header closeButton>
    <Modal.Title>Confirm Deletion</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p>Do you want to delete this record?</p>
  </Modal.Body>
  <Modal.Footer>
    <Button
      variant="secondary"
      onClick={() => setShowDeleteConfirmModal(false)}
    >
      Cancel
    </Button>
    <Button
      variant="danger"
      onClick={() => {
      if(!showSampleData){
        deleteRow(
          recordToDeleteIsSample, recordToDelete, '', 'con',
        )
      }
      else{
        deleteRow(
          recordToDeleteIsSample, recordToDelete,
          'csv',
          'samp',
        )
      }
        setShowDeleteConfirmModal(false);
      }}
    >
      Delete
    </Button>
  </Modal.Footer>
</Modal>


      {/* FOR EDIT / ADD NEW ROW */}

      <Modal
        show={showNewRowModal}
        onHide={() => {
          setShowNewRowModal(false);
          setEditingRowIndex(null);
          setNewRowData([])
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editingRowIndex !== null ? 'Edit Row' : 'Add New Row'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data.columns.map(
            (column, index) =>
              index > 1 && (
                <Form.Group key={column} className="mb-3">
                  <Form.Label>{column}</Form.Label>
                  <Form.Control
                    type={
                      data.attributes[index]?.datatype === 'number'
                        ? 'number'
                        : 'text'
                    }
                    value={newRowData[index]}
                    onChange={(e) => {
                      if (Array.isArray(newRowData)) {
                        // Handle as an array
                        const updatedRowData = [...newRowData];
                        updatedRowData[index] = e.target.value;
                        setNewRowData(updatedRowData);
                      } else {
                        // Handle as an object
                        const updatedRowData = { ...newRowData };
                        updatedRowData[index] = e.target.value;
                        setNewRowData(updatedRowData);
                      }
                    }}
                    required={data.attributes[index]?.mandatory}
                  />
                </Form.Group>
              ),
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowNewRowModal(false);
              setEditingRowIndex(null);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={addOrUpdateRow}>
            {editingRowIndex !== null ? 'Update Row' : 'Add Row'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* FOR EDIT COLUMN */}
      {
      editingColumnIndex !== null &&  <EditColumnClassifier 
      selectedColumn={selectedColumn}
       setSelectedColumn={setSelectedColumn} 
       setConnectedClassifier={setConnectedClassifier}
        setFileName={setFileName}
         updateColumn={()=>updateColumn(editingColumnIndex, editedColumn)}  
          editedColumn={editedColumn}
           setEditedColumn={setEditedColumn} 
           setEditingColumnIndex={ ()=>setEditingColumnIndex(null)} />
      }
      
      {/* <Modal
        show={editingColumnIndex !== null}
        onHide={() => setEditingColumnIndex(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Column: {editedColumn?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Column Name</Form.Label>
            <Form.Control
              type="text"
              value={editedColumn?.name || ''}
              onChange={(e) =>
                setEditedColumn((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={editedColumn?.description || ''}
              onChange={(e) =>
                setEditedColumn((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Data Type</Form.Label>
            <Form.Select
              value={editedColumn?.datatype || ''}
              onChange={(e) =>
                setEditedColumn((prev) => ({
                  ...prev,
                  datatype: e.target.value,
                }))
              }
            >
              <option value="text">Text</option>
              <option value="number">Number</option>
              <option value="boolean">Boolean</option>
              <option value="date">Date</option>
            </Form.Select>
          </Form.Group>

          <Form.Check
            type="checkbox"
            label="Unique"
            checked={editedColumn?.unique || false}
            onChange={(e) =>
              setEditedColumn((prev) => ({ ...prev, unique: e.target.checked }))
            }
            className="mb-3"
          />
          <Form.Check
            type="checkbox"
            label="Mandatory"
            checked={editedColumn?.mandatory || false}
            onChange={(e) =>
              setEditedColumn((prev) => ({
                ...prev,
                mandatory: e.target.checked,
              }))
            }
            className="mb-3"
          />
          <Form.Check
            type="checkbox"
            label="Computed Field"
            checked={editedColumn?.computeField || false}
            onChange={(e) =>
              setEditedColumn((prev) => ({
                ...prev,
                computeField: e.target.checked,
              }))
            }
            className="mb-3"
          />
            <Form.Check
            type="checkbox"
            label="Search"
            checked={editedColumn?.search || false}
            onChange={(e) =>
              setEditedColumn((prev) => ({
                ...prev,
                search: e.target.checked,
              }))
            }
            className="mb-3"
          />
         
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setEditingColumnIndex(null)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => updateColumn(editingColumnIndex, editedColumn)}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* FOR ADD NEW COLUMN */}

      <Modal
        show={showAddColumnModal}
        onHide={() => setShowAddColumnModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Column</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Column Name</Form.Label>
            <Form.Control
              type="text"
              value={newColumnData.name}
              onChange={(e) =>
                setNewColumnData((prev) => ({ ...prev, name: e.target.value }))
              }
              placeholder="Enter column name"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={newColumnData.description}
              onChange={(e) =>
                setNewColumnData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              placeholder="Enter column description"
            />
          </Form.Group>


          {/* CHOOSE COLUMN FOR NEW COLUMN */}

          <Form.Group className="mb-3">
            <Form.Label>Adjacent Column</Form.Label>
            <Form.Select
              value={newColumnData?.columnName || ''}
              onChange={chooseColumnNameForNewColumn}
            >
              
              {newColumnHeader.filter(it => it.id !== 'FileName').map((it, idx) => {
                  // if(it.id !== 'Date/Time'){
                  //   return 
                  // }
                  return <option key={idx} value={it.id} dataIndex={it.index}>{it.id}</option>
                

              })}

            </Form.Select>
          </Form.Group>

          {/* CHOOSE POSITION FOR NEW COLUMN */}

          <Form.Group className="mb-3">
  <Form.Label>Position</Form.Label>
  <Form.Select
    value={addColumnPosition || 'right'} // Default to 'right' if addColumnPosition is null
    onChange={(e) => setAddColumnPosition(e.target.value)}
  >
    {!newColumnData?.columnName || newColumnData?.columnName === "Date/Time" ? (
      <option value="right">Right</option>
    ) : (
      <>
        <option value="left">Left</option>
        <option value="right">Right</option>
      </>
    )}
  </Form.Select>
</Form.Group>



          <Form.Group className="mb-3">
            <Form.Label>Data Type</Form.Label>
            <Form.Select
              value={newColumnData.datatype}
              onChange={(e) =>
                setNewColumnData((prev) => ({
                  ...prev,
                  datatype: e.target.value,
                }))
              }
            >
              <option value="text">Text</option>
              <option value="number">Number</option>
              <option value="boolean">Boolean</option>
              <option value="date">Date</option>
            </Form.Select>
          </Form.Group>
          <Form.Check
            type="checkbox"
            label="Unique"
            checked={newColumnData.unique}
            onChange={(e) =>
              setNewColumnData((prev) => ({
                ...prev,
                unique: e.target.checked,
              }))
            }
            className="mb-3"
          />
          <Form.Check
            type="checkbox"
            label="Mandatory"
            checked={newColumnData.mandatory}
            onChange={(e) =>
              setNewColumnData((prev) => ({
                ...prev,
                mandatory: e.target.checked,
              }))
            }
            className="mb-3"
          />
          <Form.Check
            type="checkbox"
            label="Computed Field"
            checked={newColumnData.computeField}
            onChange={(e) =>
              setNewColumnData((prev) => ({
                ...prev,
                computeField: e.target.checked,
              }))
            }
            className="mb-3"
          />
          <Form.Check
            type="checkbox"
            label="Search"
            checked={newColumnData.search}
            onChange={(e) =>
              setNewColumnData((prev) => ({
                ...prev,
                search: e.target.checked,
              }))
            }
            className="mb-3"
          />
          {/* {newColumnData.computeField && (
            <Form.Group className="mb-3">
              <Form.Label>Compute Function</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={newColumnData.computeFunction || ''}
                onChange={(e) =>
                  setNewColumnData((prev) => ({
                    ...prev,
                    computeFunction: e.target.value,
                  }))
                }
                placeholder="Enter JavaScript function"
              />
            </Form.Group>
          )} */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAddColumnModal(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => addColumn(addColumnPosition)}
          >
            Add Column
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
