import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import './UploadTextModal.css';
import { Checkbox, FormControlLabel, Input } from '@mui/material';
import { green } from '@mui/material/colors';
import { styled } from '@mui/styles';
import { toast } from 'react-toastify';

const UploadTextModal = (props) => {
  const {
    handleClose,
    show,
    handleUploadFile,
    loading,
    list,
    setLoadingData,
    textCheckBox,
    setTextCheckBox,
    merge,
    setMerge,
  } = props;
  const [uploadText, setUploadText] = useState('');
  const [returnRowsLimit, setReturnRowsLimit] = useState(0);
  const [model, setModel] = useState(1);

  useEffect(() => {
    setUploadText('');

    if (show) {
      setTextCheckBox(false);
      setMerge(false);
    }
  }, [show]);
  const CustomCheckbox = styled(Checkbox)`
    color: green; /* Replace with your desired shade of green */
    &:focus {
      background-color: green; /* Replace with your desired focus color */
    }
    &.Mui-checked {
      color: green; /* Replace with your desired shade of green */
    }
  `;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-center" style={{ fontSize: '24px' }}>
          Upload text
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          name="uploadText"
          onChange={(e) => {
            setUploadText(e.target.value);
          }}
          style={{
            border: '1px solid #E2E2EA',
            borderRadius: '10px',
            padding: '15px',
            width: '100%',
          }}
          rows="10"
          minlength="5"
        >
          {uploadText}
        </textarea>
        <div
          style={{
            background: '#F1F1F5',
            borderRadius: '11px',
            width: '100px',
            textAlign: 'center',
            position: 'absolute',
            top: '5px',
            left: '40px',
            color: '#44444F',
          }}
        >
          Text or website links
        </div>
        {uploadText?.length < 10 && (
          <h5 className="text-center" style={{ fontSize: '14px' }}>
            minimum 10 characters
          </h5>
        )}
        <div className="d-flex">
          <FormControlLabel
            className="d-flex align-items-center"
            control={
              <CustomCheckbox
                defaultChecked={textCheckBox}
                onChange={(e) => {
                  setTextCheckBox(e.target.checked);
                }}
              />
            }
            label="Process URLs"
          />
          <FormControlLabel
            className="d-flex align-items-center  ms-2"
            control={
              <CustomCheckbox
                defaultChecked={merge}
                onChange={(e) => {
                  setMerge(e.target.checked);
                }}
              />
            }
            label="Merge to one row (If checked it will return only one merged row of data)"
          />
        </div>
        <div className="d-flex">
          <div className="mb-3 ">
            <span>Return rows limit (0 for unlimited rows): </span>
            <Input
              type="number"
              value={returnRowsLimit}
              onChange={(e) => {
                setReturnRowsLimit(e.target.value);
              }}
            />
          </div>
          {JSON.parse(localStorage.getItem('user'))?.isDeveloper ? (
            <div className="mb-3 ">
              <span>AI model: </span>
              <Input
                type="number"
                value={model}
                onChange={(e) => {
                  if (e.target.value < 0) {
                    toast(`Value should be greater then 0`, {
                      type: 'warning',
                    });
                  } else {
                    setModel(e.target.value);
                  }
                }}
              />
            </div>
          ) : (
            <div className="mb-3" />
          )}
        </div>

        <div className="d-flex justify-content-center">
          <Button
            variant="dark"
            disabled={
              uploadText?.length < 10
                ? true
                : false || returnRowsLimit === '' || model === ''
            }
            onClick={() => {
              const file = new File([uploadText], 'text.txt', {
                type: 'text/plain',
              });
              handleUploadFile(file, '3', returnRowsLimit, model);
            }}
          >
            {loading === '3' ? (
              <Spinner animation="border" variant="secondary" />
            ) : (
              'Upload'
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadTextModal;
