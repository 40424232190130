import React, { useContext } from 'react';
import styles from '../components/PricingModal.module.css';
import { Button, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import api from '../api';
import { PlansContext } from '../context/plans/plans';
import { useState } from 'react';

const planObj = {
  free: 'FREE',
  plus: 'PLUS',
  enterprise: 'ENTERPRISE',
  trial: 'TRIAL',

};

function PricingModal({
  plan,
  userPlan: commingPlan,
  setPriceModalShow
}) {
  const { userPlan, handleValidatePlan, deactivatedSub } =
    useContext(PlansContext);
  const [isLoading, setIsLoading] = useState(false);

  const createSubSession = async ({ durationMonths, plan, priceTag }) => {
    try {
      setIsLoading(true);
      const res = await api.get('/subscription/create-checkout-session', {
        params: {
          durationMonths,
          plan,
          priceTag,
        },
      });
      if (res.data) {
        if (res.data.newSubscription === true) {
          window.location.replace(res.data.url);
          setIsLoading(false);
        } else {
          handleValidatePlan();
          setIsLoading(false);
          toast('Your package activated successfully (code 103)', {
            type: 'success',
          });
          setPriceModalShow(false);
        }
      }
    } catch (err) {
      console.log('Error', err);
      // navigate('/payment/plus');
      setPriceModalShow(false);
    }
  };

  const subscribePlan = async ({ plan, durationMonths, priceTag }) => {
    try {
      if (plan === planObj.free) {
        const res = await api.post(`/user/subscription?plan=free`);
        if (res.data.message) {
          handleValidatePlan();
          toast('Your package activated successfully (code 102)', {
            type: 'success',
          });
          setPriceModalShow(false);
        }
      } 
      
      else if (plan !== planObj.free) {
        createSubSession({ durationMonths, plan, priceTag });
      }
    } catch (error) {
      toast(error?.response?.data?.message, { type: 'error' });
    }
  };

  const enterpricePlan = async () => {
    const mailtoLink = 'mailto:contacts@datatera.ai';
    window.location.href = mailtoLink;
  };

  const manageSubscription = async () => {
    if (userPlan?.[0]?.sessionId) {
      setIsLoading(true);
      try {
        const res = await api.get('/subscription/manage-subscription', {
          params: {
            sessionId: userPlan[0].sessionId,
          },
        });
        if (res.data) {
          setIsLoading(false);
          window.location.replace(res.data.url);
        }
      } catch (err) {
        console.log('Error', err);
      }
    }
  };

  const handleButtonClick = () => {
    if (plan.title === planObj.enterprise) {
      enterpricePlan();
    }
    if (plan.title !== planObj.enterprise) {     

      subscribePlan({
        plan: plan?.title,
        durationMonths: plan?.durationMonths,
        priceTag: plan?.price,
      });
    }
  };

  let priceDisplay = plan.price !== '' ? `$${plan.price}` : '';
  return (
    <>
      {isLoading ? (
        <div className={styles.preLoader}>
          <CircularProgress />
        </div>
      ) : (
        <div className={styles.your_plan}>
          <div className={styles.title}>
            <div className={styles.title_row}>
              <div>
                { userPlan && userPlan[0]?.name === "TRIAL" && plan?.title==="FREE"?"TRIAL":  `${plan?.title}`}

                <div className={styles.p_color}> {priceDisplay}</div>
              </div>
            </div>
          </div>
          <Button
            onClick={handleButtonClick}
            className={
              userPlan && userPlan[0]?.name === plan?.title || userPlan && userPlan[0]?.name === "TRIAL" && plan?.title==="FREE"
                ? styles?.freebtn
                : styles?.btn
            }
            style={
              userPlan && userPlan[0]?.name === plan?.title
                ? { cursor: 'not-allowed' }
                : {}
            }
  disabled={userPlan &&( userPlan[0]?.name === plan?.title ||  plan?.title==="FREE" ||  ( userPlan[0]?.name === "TRIAL" && plan?.title==="FREE" ) || ( userPlan[0]?.name === "FREE" && plan?.title==="FREE" ) )} // Disable only for this specific plan

          >
               {userPlan && userPlan[0]?.name === plan?.title || userPlan && userPlan[0]?.name === "TRIAL" && plan?.title==="FREE"
              ? 'Current plan'
              : plan?.btn_title}
          </Button>
          <div>
            <div className={styles.description}>
              <div>
                <img
                  src={
                    userPlan && userPlan[0]?.name === plan?.title
                      ? '/icon_2.png'
                      : '/icon_1.png'
                  }
                  alt="img"
                />
              </div>
              <div>{plan?.d_1}</div>
            </div>
            <div className={styles.description}>
              <div>
                <img
                  src={
                    userPlan && userPlan[0]?.name === plan?.title
                      ? '/icon_2.png'
                      : '/icon_1.png'
                  }
                  alt="img"
                />
              </div>
              <div>{plan?.d_2}</div>
            </div>

            <div className={styles.description}>
              <div>
                <img
                  src={
                    userPlan && userPlan[0]?.name === plan?.title
                      ? '/icon_2.png'
                      : '/icon_1.png'
                  }
                  alt="img"
                />
              </div>
              <div>{plan?.d_3}</div>
            </div>
          </div>
          {userPlan && userPlan[0]?.name === plan.title 
          && plan.title !== planObj.free
          && userPlan[0]?.sessionId &&
          (
            <div
              onClick={manageSubscription}
              className={`mt-2 + ${styles.btn}`}
            >
              Manage subscription
            </div>
          )}
        </div>

        // <div className={styles.your_plan}>
        //   <div className={styles.title}>
        //     <div className={styles.title_row}>
        //       <div>{plan?.title}</div>
        //       {plan?.title === planObj.plus &&
        //         deactivatedSub?.name !== planObj.plus && (
        //           <div className={styles.p_color}>
        //             {userPlan && userPlan[0]?.interval === 'year'
        //               ? yearPlanPrice
        //               : monthPlanPrice}
        //           </div>
        //         )}
        //     </div>
        //   </div>

        //   <div className={styles.modal_btn}>
        //     <a
        //       style={{ width: '100%' }}
        //       href={
        //         plan?.title === planObj.enterprise &&
        //         'mailto:contacts@datatera.ai'
        //       }
        //     >
        //       {deactivatedSub?.name === planObj.plus &&
        //       plan?.title === planObj.plus ? (
        //         <Button
        //           onClick={() =>
        //             subscribePlan({
        //               plan: plan?.title,
        //             })
        //           }
        //           className={styles?.btn}
        //         >
        //           ACTIVATE PACKAGE
        //         </Button>
        //       ) : (
        //         <Button
        //           onClick={() => {
        //             if (
        //               userPlan &&
        //               userPlan[0]?.name !== plan?.title &&
        //               !(
        //                 userPlan[0]?.name === planObj.plus &&
        //                 plan?.title === planObj.free
        //               )
        //             ) {
        //               plan?.title === planObj.enterprise
        //                 ? enterpricePlan()
        //                 : subscribePlan({
        //                     plan: plan?.title,
        //                     durationMonths: '1',
        //                     priceTag: monthPlanPrice,
        //                   });
        //             }
        //           }}
        //           className={
        //             userPlan && userPlan[0]?.name === plan?.title
        //               ? styles?.freebtn
        //               : styles?.btn
        //           }
        //           style={
        //             (userPlan && userPlan[0]?.name === plan?.title) ||
        //             (userPlan &&
        //               userPlan[0]?.name === planObj.plus &&
        //               plan?.title === planObj.free)
        //               ? { cursor: 'not-allowed' }
        //               : {}
        //           }
        //         >
        //           {userPlan && userPlan[0]?.name === plan?.title
        //             ? 'Current plan'
        //             : plan?.btn_title}
        //         </Button>
        //       )}
        //     </a>
        //   </div>
        //   {userPlan &&
        //     plan?.title === planObj.plus &&
        //     userPlan[0]?.name !== planObj.plus &&
        //     deactivatedSub?.name !== planObj.plus && (
        //       <div className={styles.yearly_btn_parent}>
        //         <div
        //           onClick={() => {
        //             subscribePlan({
        //               plan: planObj.plus,
        //               durationMonths: '12',
        //               priceTag: yearPlanPrice,
        //             });
        //           }}
        //           className={`mt-4 + ${styles.yearly_btn}`}
        //         >
        //           Save 20% on yearly
        //         </div>
        //       </div>
        //     )}
        //   <div className={styles.description}>
        //     <div>
        //       <img
        //         src={
        //           userPlan && userPlan[0]?.name === plan?.title
        //             ? '/icon_2.png'
        //             : '/icon_1.png'
        //         }
        //         alt="img"
        //       />
        //     </div>
        //     <div>{plan?.d_1}</div>
        //   </div>
        //   <div className={styles.description}>
        //     <div>
        //       <img
        //         src={
        //           userPlan && userPlan[0]?.name === plan?.title
        //             ? '/icon_2.png'
        //             : '/icon_1.png'
        //         }
        //         alt="img"
        //       />
        //     </div>
        //     <div>{plan?.d_2}</div>
        //   </div>
        //   <div className={styles.description}>
        //     <div>
        //       <img
        //         src={
        //           userPlan && userPlan[0]?.name === plan?.title
        //             ? '/icon_2.png'
        //             : '/icon_1.png'
        //         }
        //         alt="img"
        //       />
        //     </div>
        //     <div>{plan?.d_3}</div>
        //   </div>
        //   {userPlan &&
        //     userPlan[0]?.name === planObj.plus &&
        //     plan?.title === planObj.plus &&
        //     userPlan[0]?.sessionId &&
        //     (
        //       <div
        //         onClick={manageSubscription}
        //         className={`mt-2 + ${styles.btn}`}
        //       >
        //         Manage subscription
        //       </div>
        //     )}
        // </div>

      )}
    </>
  );
}

export default PricingModal;
