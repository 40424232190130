import { useState, useRef, useEffect, useMemo } from "react";
import { X, Upload, FileText, Check, Plus, Trash2, Edit2 } from "lucide-react";
import {
  Button,
  TextField,
  TextareaAutosize,
  Checkbox,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Box,
  Typography,
  IconButton,
  FormControlLabel,
  Snackbar,
  Alert,
} from "@mui/material";

export default function ColumnEditor({
  selectedColumn,
  setSelectedColumn,
  setConnectedClassifier,
  setFileName,
  updateColumn,
  editedColumn,
  setEditedColumn,
  setEditingColumnIndex,
}) {
  const [csvData, setCsvData] = useState(null);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const fileInputRef = useRef(null);

  const [editingRow, setEditingRow] = useState(null);
  const [editedRowData, setEditedRowData] = useState([]);
  const [showAddRowDialog, setShowAddRowDialog] = useState(false);
  const [newRowData, setNewRowData] = useState([]);

  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "info" });

  const isNewRowValid = useMemo(() => {
    return newRowData.some((value) => value.trim() !== "");
  }, [newRowData]);

  // Initialize CSV data if connectedClassifier is present
  useEffect(() => {
    if (editedColumn?.connectedClassifier?.length > 0) {
      const headers = Object.keys(editedColumn.connectedClassifier[0]);
      const rows = editedColumn.connectedClassifier.map((row) => headers.map((header) => row[header]));
      setCsvData({
        headers,
        rows,
        fileName: editedColumn.connectedClassifierFileName,
      });
      setSelectedColumn(editedColumn.connectedClassifierField || null);
    }
  }, [editedColumn]);

  const handleSaveChanges = () => {
    updateColumn();
  };

  const transformCSVData = (csvData) => {
    if (!csvData || !csvData.headers || !csvData.rows) return [];

    const transformedData = csvData.rows.map((row) => {
      const rowObject = {};
      csvData.headers.forEach((header, index) => {
        rowObject[header] = row[index] || "";
      });
      return rowObject;
    });

    setFileName(csvData?.fileName);
    setConnectedClassifier(transformedData);
    return transformedData;
  };

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result;
      parseCSV(text, file.name);
    };
    reader.readAsText(file);
  };

  const parseCSV = (csvText, fileName) => {
    const lines = csvText.split("\n");

    const parseCSVLine = (line) => {
      const result = [];
      let inQuotes = false;
      let currentField = "";

      for (let i = 0; i < line.length; i++) {
        const char = line[i];
        if (char === '"') {
          inQuotes = !inQuotes;
        } else if (char === "," && !inQuotes) {
          result.push(currentField.trim());
          currentField = "";
        } else {
          currentField += char;
        }
      }
      result.push(currentField.trim());
      return result;
    };

    const headers = parseCSVLine(lines[0]);
    const rows = lines
      .slice(1)
      .filter((line) => line.trim() !== "")
      .map((line) => parseCSVLine(line));

    setCsvData({ headers, rows, fileName });
    setShowPreviewDialog(true);
    setSelectedColumn(null);
  };
  const handleRemoveCSV = () => {
    setCsvData(null);
    setSelectedColumn(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  
    // Clear connectedClassifier-related fields in editedColumn
    setEditedColumn((prev) => {
      const updatedColumn = { ...prev };
      delete updatedColumn.connectedClassifier;
      delete updatedColumn.connectedClassifierField;
      delete updatedColumn.connectedClassifierFileName;
      return updatedColumn;
    });
  
    // Clear connectedClassifier and fileName in parent state
    setConnectedClassifier([]);
    setFileName("");
  };
  const handleSelectColumn = (columnIndex) => {
    if (!csvData) return;
    setSelectedColumn(csvData.headers[columnIndex]);
  };

  const handleEditRow = (rowIndex) => {
    if (!csvData) return;
    setEditingRow(rowIndex);
    setEditedRowData([...csvData.rows[rowIndex]]);
  };

  const handleSaveEditedRow = () => {
    if (!csvData || editingRow === null) return;
    const newRows = [...csvData.rows];
    newRows[editingRow] = editedRowData;
    setCsvData({ ...csvData, rows: newRows });
    setEditingRow(null);
  };

  const handleDeleteRow = (rowIndex) => {
    if (!csvData) return;
    const newRows = csvData.rows.filter((_, index) => index !== rowIndex);
    setCsvData({ ...csvData, rows: newRows });
  };

  const handleAddRow = () => {
    if (!csvData) return;
    setNewRowData(Array(csvData.headers.length).fill(""));
    setShowAddRowDialog(true);
  };

  const handleSaveNewRow = () => {
    if (!csvData) return;
    const selectedColumnIndex = csvData.headers.indexOf(selectedColumn || "");
    if (selectedColumnIndex !== -1 && !newRowData[selectedColumnIndex]) {
      setSnackbar({ open: true, message: "The selected column must have a value.", severity: "error" });
      return;
    }
    const updatedRows = [...csvData.rows, newRowData];
    setCsvData({ ...csvData, rows: updatedRows });
    setShowAddRowDialog(false);
    setNewRowData([]);
  };
  const handleCheckboxChange = (field, isChecked) => {
    setEditedColumn((prev) => {
      const newState = { ...prev };
      
      // First update the field that was clicked
      newState[field] = isChecked;
      
      // Then handle all the dependencies
      if (field === 'computeField' && isChecked) {
        newState.unique = false;
        newState.mandatory = false;
        newState.search = false;
      }
      else if (field === 'search' && isChecked) {
        newState.unique = false;
        newState.mandatory = false;
        newState.computeField = false;
      }
      else if (field === 'unique' && isChecked) {
        newState.header = false;
        newState.computeField = false;
        newState.search = false;
      }
      else if (field === 'mandatory' && isChecked) {
        newState.computeField = false;
        newState.search = false;
      }
      else if (field === 'header' && isChecked) {
        newState.unique = false;
      }
      
      return newState;
    });
  };
  

  const handleConfirmSelection = () => {
    if (!csvData || !selectedColumn) return;
    setShowPreviewDialog(false);
    transformCSVData(csvData);
  };
  return (
    <Box
      sx={{
        position: "fixed",
        inset: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "8px",
          width: "100%",
          height: "100vh",
          overflow: "scroll",
          maxWidth: "512px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px",
            borderBottom: "1px solid #e5e7eb",
          }}
        >
          <Typography variant="h6">Edit Column: {editedColumn?.name}</Typography>
          <IconButton onClick={() => setEditingColumnIndex()}>
            <X size={24} />
          </IconButton>
        </Box>

        <Box sx={{ padding: "16px", display: "flex", flexDirection: "column", gap: "24px" }}>
          {/* Column Name */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography variant="body2" fontWeight="500">
              Column Name
            </Typography>
            <TextField
              id="name"
              value={editedColumn?.name}
              setEditedColumn
              
              onChange={(e) =>  setEditedColumn((prev) => ({ ...prev, name: e.target.value }))
                // setColumnName(e.target.value)
              }
              fullWidth
            />
          </Box>

          {/* Description */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography variant="body2" fontWeight="500">
              Description
            </Typography>
            <TextareaAutosize
              id="description"
              value={editedColumn?.description}
              onChange={(e) =>  setEditedColumn((prev) => ({ ...prev, description: e.target.value }))}
              // onChange={(e) =>
              //    setDescription(e.target.value)
              //   }
              minRows={3}
              style={{ width: "100%", padding: "8px", borderRadius: "4px", border: "1px solid #e5e7eb" }}
            />
          </Box>

          {/* Data Type */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography variant="body2" fontWeight="500">
              Data Type
            </Typography>
            <Select
              value={editedColumn?.datatype}
              onChange={(e) =>  setEditedColumn((prev) => ({ ...prev, datatype: e.target.value }))}
              // onChange={(e) => setDataType(e.target.value)}
              fullWidth
            >
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="datetime">Date</MenuItem>
              <MenuItem value="boolean">Boolean</MenuItem>
            </Select>
          </Box>

          {/* Classifier Table (CSV) */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", borderTop: "1px solid #e5e7eb", paddingTop: "16px" }}>
            <Typography variant="body2" fontWeight="500">
              Classifier Table (CSV)
            </Typography>

            {csvData ? (
              <Box sx={{ border: "1px solid #e5e7eb", borderRadius: "6px", padding: "12px", display: "flex", flexDirection: "column", gap: "12px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <FileText size={16} color="primary" />
                    <Typography variant="body1" fontWeight="500" noWrap sx={{ maxWidth: "200px" }}>
                      {csvData.fileName}
                    </Typography>
                  </Box>
                  <Button variant="text" size="small" onClick={handleRemoveCSV}>
                    <X size={16} />
                  </Button>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                  {selectedColumn ? (
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Box>
                        <Typography variant="body2" color="textSecondary">
                          Linked Column:
                        </Typography>
                        <Chip label={selectedColumn} variant="outlined" sx={{ mt: 1 }} />
                      </Box>
                      <Button variant="outlined" size="small" onClick={() => setShowPreviewDialog(true)}>
                        Change
                      </Button>
                    </Box>
                  ) : (
                    <Button variant="outlined" fullWidth onClick={() => setShowPreviewDialog(true)}>
                      Select Column to Link
                    </Button>
                  )}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  border: "1px dashed #e5e7eb",
                  borderRadius: "6px",
                  padding: "24px",
                  textAlign: "center",
                }}
              >
                <input type="file" accept=".csv" onChange={handleFileUpload} style={{ display: "none" }} ref={fileInputRef} />
                <Upload size={32} color="action" sx={{ mb: 2 }} />
                <Typography variant="body1" fontWeight="500" sx={{ mb: 1 }}>
                  Upload CSV Classifier
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                  Upload a CSV file containing classifier data
                </Typography>
                <Button variant="outlined" size="small" onClick={() => fileInputRef.current?.click()}>
                  Select CSV File
                </Button>
              </Box>
            )}
          </Box>

          {/* Checkboxes */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
  <FormControlLabel
    control={
      <Checkbox
        checked={editedColumn?.unique || false}
        onChange={(e) => handleCheckboxChange('unique', e.target.checked)}
      />
    }
    label="Unique"
  />
  <FormControlLabel
    control={
      <Checkbox
        checked={editedColumn?.mandatory || false}
        onChange={(e) => handleCheckboxChange('mandatory', e.target.checked)}
      />
    }
    label="Mandatory"
  />
  <FormControlLabel
    control={
      <Checkbox
        checked={editedColumn?.header || false}
        onChange={(e) => handleCheckboxChange('header', e.target.checked)}
      />
    }
    label="Header"
  />
  <FormControlLabel
    control={
      <Checkbox
        checked={editedColumn?.computeField || false}
        onChange={(e) => handleCheckboxChange('computeField', e.target.checked)}
      />
    }
    label="Computed Field"
  />
  <FormControlLabel
    control={
      <Checkbox
        checked={editedColumn?.search || false}
        onChange={(e) => handleCheckboxChange('search', e.target.checked)}
      />
    }
    label="Search"
  />
</Box>
        </Box>

        {/* Footer Buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "8px", padding: "16px", borderTop: "1px solid #e5e7eb" }}>
          <Button variant="outlined" onClick={() => setEditingColumnIndex()}>Close</Button>
          <Button variant="contained" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Box>
      </Box>

      {/* Preview Dialog */}
      <Dialog open={showPreviewDialog} onClose={() => setShowPreviewDialog(false)} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h6">Select Column from CSV</Typography>
            <IconButton onClick={() => setShowPreviewDialog(false)}>
              <X size={16} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {csvData && (
            <Box sx={{ mt: 2, display: "flex", flexDirection: "column", height: "100%", overflow: "hidden" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, pb: 2, borderBottom: "1px solid #e5e7eb" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <FileText size={20} color="primary" />
                  <Typography variant="body1" fontWeight="500">
                    {csvData.fileName}
                  </Typography>
                </Box>
                <Button variant="outlined" size="small" onClick={handleAddRow}>
                  <Plus size={16} sx={{ mr: 1 }} /> Add Row
                </Button>
              </Box>

              <Box sx={{ flexGrow: 1, overflow: "auto", border: "1px solid #e5e7eb", borderRadius: "4px", backgroundColor: "white" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ position: "sticky", left: 0, backgroundColor: "white", zIndex: 1 }}>
                        <Typography variant="body2" fontWeight="500">
                          Actions
                        </Typography>
                      </TableCell>
                      {csvData.headers.map((header, index) => (
                        <TableCell key={index} sx={{ minWidth: "150px" }}>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                            <Button
                              variant="outlined"
                              size="small"
                              sx={{
                                justifyContent: "flex-start",
                                width: "100%",
                                backgroundColor: selectedColumn === header ? "#e3f2fd" : "inherit",
                                color: selectedColumn === header ? "#1976d2" : "inherit",
                              }}
                              onClick={() => handleSelectColumn(index)}
                            >
                              {selectedColumn === header ? (
                                <Check size={16} sx={{ mr: 1 }} />
                              ) : (
                                <Box sx={{ width: "16px", height: "16px", border: "1px solid #bdbdbd", borderRadius: "2px", mr: 1 }} />
                              )}
                              <Typography variant="body2">Select</Typography>
                            </Button>
                            <Typography variant="body2" fontWeight="500" noWrap>
                              {header}
                            </Typography>
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {csvData.rows.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        <TableCell sx={{ position: "sticky", left: 0, backgroundColor: "white", zIndex: 1 }}>
                          <Box sx={{ display: "flex", gap: "8px" }}>
                            <IconButton size="small" onClick={() => handleEditRow(rowIndex)}>
                              <Edit2 size={16} />
                            </IconButton>
                            <IconButton size="small" onClick={() => handleDeleteRow(rowIndex)}>
                              <Trash2 size={16} />
                            </IconButton>
                          </Box>
                        </TableCell>
                        {row.map((cell, cellIndex) => (
                          <TableCell key={cellIndex} sx={{ backgroundColor: selectedColumn === csvData.headers[cellIndex] ? "#e3f2fd" : "inherit" }}>
                            {editingRow === rowIndex ? (
                              <TextField
                                value={editedRowData[cellIndex]}
                                onChange={(e) => {
                                  const newData = [...editedRowData];
                                  newData[cellIndex] = e.target.value;
                                  setEditedRowData(newData);
                                }}
                                size="small"
                                fullWidth
                              />
                            ) : (
                              <Typography variant="body2" noWrap>
                                {cell}
                              </Typography>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>

              {csvData.rows.length > 5 && (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 2, textAlign: "center" }}>
                  Showing all {csvData.rows.length} rows
                </Typography>
              )}

              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "8px", mt: 2, pt: 2, borderTop: "1px solid #e5e7eb" }}>
                <Button variant="outlined" onClick={() => setShowPreviewDialog(false)}>
                  Cancel
                </Button>
                <Button variant="contained" disabled={!selectedColumn} onClick={() => { setShowPreviewDialog(false); handleConfirmSelection();transformCSVData(csvData) }}>
                  Confirm Selection
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>

      {/* Add Row Dialog */}
      <Dialog open={showAddRowDialog} onClose={() => setShowAddRowDialog(false)} maxWidth="lg"  sx={{ "& .MuiDialog-paper": { width: "50%" } }}>
        <DialogTitle>Add New Row</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", mt: 2 }}>
            {csvData?.headers.map((header, index) => (
              <Box key={index} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Typography variant="body2" fontWeight="500">
                  {header}
                </Typography>
                <TextField
                  value={newRowData[index] || ""}
                  onChange={(e) => {
                    const updatedNewRowData = [...newRowData];
                    updatedNewRowData[index] = e.target.value;
                    setNewRowData(updatedNewRowData);
                  }}
                  required={header === selectedColumn}
                  fullWidth
                />
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setShowAddRowDialog(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSaveNewRow} disabled={!isNewRowValid}>
            Add Row
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}