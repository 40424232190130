import { Box, FormLabel, IconButton, InputAdornment, TextField, Checkbox, styled } from '@mui/material';
import { signInWithPopup } from 'firebase/auth';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiFillInfoCircle, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import api from '../../api/index';
import googleLogo from '../../assets/images/googlelogo.png';
import logo from '../../assets/images/logo.jpg';
import { auth, provider } from '../../config/firebaseConfig';
import { AuthContext } from '../../context/auth';
import './signup.css';

import Hotjar from '@hotjar/browser';
import AuthLayout from '../../components/authLayout/authLayout';

const Signup = () => {
  const { isLogin, loginSuccess } = useContext(AuthContext);

  const navigate = useNavigate();

  const [termRead, setTermRead] = useState(false);
  const [showpassword, setShowpassword] = useState(false);
  const [showconfirmpassword, setConfirmpassword] = useState(false);

  const initialValues = {
    email: '',
    password: '',
    passwordConfirm: '',
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email format').required('Please Enter Your Email'),
    password: yup
      .string()
      .required('Please Enter Your Password')
      .matches(/^\S*$/, 'Password cannot contain spaces'),
    passwordConfirm: yup
      .string()
      .required('Please Enter Your Confirm Password')
      .matches(/^\S*$/, 'Password cannot contain spaces'),
    // passwordConfirm: yup
    // .string()
    // .oneOf([yup.ref("password"), null], "Passwords must match")
    // .required(),
  });

  const onSubmit = async (values, resetForm) => {
    try {
      if (
        values?.password === values?.passwordConfirm &&
        values?.password?.length >= 8
      ) {
        const res = await api.post('/user/register', values);
        toast('Verification link sent to your registered email address!', {
          type: 'success',
        });
        Hotjar.event(
          'User sign up success, redirected to email validation page.',
        );
        navigate('/');
        resetForm();
      } else if (values?.password?.length < 8) {
        toast('Password must contains at least 8 characters!', {
          type: 'warning',
        });
      } else {
        toast('Password and confirm password should be same!', {
          type: 'warning',
        });
      }
    } catch (error) {
      toast(error?.response?.data?.message, { type: 'error' });
      console.log("Error",error);
    }
  };

  const handleOnClick = async () => {
    signInWithPopup(auth, provider).then(async (res) => {
      let data = {
        name: res?.user?.displayName,
        email: res?.user?.email,
        photo: res?.user?.photoURL,
        token: res?.user?.accessToken,
      };
      try {
        // let res = await api.post("/user/googleSignin", data);
        // navigate("/app");
        // localStorage.setItem("user", JSON.stringify(res.data.user));
        let res = await api.post('/user/googleSignin', data);
        loginSuccess(res.data.token, res.data.data.user);
        Hotjar.event('User login success');
        navigate('/');
      } catch (error) {
        toast('Error while logged in!', { type: 'error' });
        console.log("Error",error);
      }
    });
  };
  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  const CustomCheckbox = styled(Checkbox)``;
  return (
    <AuthLayout>
        <div className="signup_main">
      {/* <div className="pic_div-register">
        <a href="https://www.datatera.ai/" target="_blank">
          <img src={logo} className="logo" />
        </a>
      </div> */}
      <div className="signUpInner">
        <div className="signup">
          <h6 className="register_heading" >Register</h6>
          <p className="explore_future_heading">Explore the future with us</p>

          <Box
            className="form"
            component="form"
            noValidate
            onSubmit={formik.handleSubmit}
          >
             <FormLabel htmlFor="email"  className={formik.errors.email && formik.touched.email ? 'error' : ''}>
                Email <span style={{ color: 'red' }}>*</span>
              </FormLabel>
            <TextField
              type="email"
              name="email"
              variant="outlined"
              className="register_input_field"
              {...formik.getFieldProps('email')}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          
            <FormLabel htmlFor="password"  className={formik.errors.password && formik.touched.password ? 'error' : ''}>
                Password <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <TextField
                type={showpassword ? 'text' : 'password'}
                name="password"
                variant="outlined"
                className="register_input_field"
                {...formik.getFieldProps('password')}
                onChange={(e) => {
                  formik.setFieldValue('password', e?.target?.value?.trim());
                }}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                      >
                        {!showpassword ? <AiOutlineEye
                className="eye_icon_signup"
                onClick={(e) => {
                  e.preventDefault();
                  setShowpassword(!showpassword);
                }}
              /> :   <AiOutlineEyeInvisible
                className="eye_icon_signup"
                onClick={(e) => {
                  setShowpassword(!showpassword);
                }}
              />
                        }
                      </IconButton>
                      {formik.errors.password && formik.touched.password ? (
                        <AiFillInfoCircle  sx={{ color: '#DC3545' }} fontSize="medium" />
                      ) : (
                        ''
                      )}
                    </InputAdornment>
                  ),
                }}
              />

           
            <FormLabel htmlFor="passwordConfirm"  className={formik.errors.passwordConfirm && formik.touched.passwordConfirm ? 'error' : ''}>
            Password Confirm  <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <div className="eye_icon_main">
              <TextField
                type={showconfirmpassword ? 'text' : 'password'}
                name="passwordConfirm"
                className="register_input_field"
                variant="outlined"
                {...formik.getFieldProps('passwordConfirm')}
                onChange={(e) => {
                  formik.setFieldValue(
                    'passwordConfirm',
                    e?.target?.value?.trim(),
                  );
                }}
                error={
                  formik.touched.passwordConfirm &&
                  Boolean(formik.errors.passwordConfirm)
                }
                helperText={
                  formik.touched.passwordConfirm &&
                  formik.errors.passwordConfirm
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                      >
                        {!showconfirmpassword ? <AiOutlineEye
                className="eye_icon_signup"
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmpassword(!showconfirmpassword);
                }}
              /> :   <AiOutlineEyeInvisible
                className="eye_icon_signup"
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmpassword(!showconfirmpassword);
                }}
              />
                        }
                      </IconButton>
                      {formik.errors.password && formik.touched.password ? (
                        <AiFillInfoCircle  sx={{ color: '#DC3545' }} fontSize="medium" />
                      ) : (
                        ''
                      )}
                    </InputAdornment>
                  ),
                }}
              />

            
            </div>
            <div className="checkbox_w_title mb-5">
              <CustomCheckbox
                id="check"
                checked={termRead}
                onChange={(e) => {
                  setTermRead(!termRead);
                }}
              />
              <p>
                I have read and agree to Datatera.ai's{' '}
                <a
                  href="https://www.datatera.ai/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  href="https://www.datatera.ai/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className="button_main">
             
              <Button className="signup_btn" type="submit" disabled={!termRead}>
                Signup
              </Button>
            </div>
          </Box>
          <div className="signin_div_main">
  <p className="signin_with_email">
    <span className="signin_email_span">Or sign in with e-mail</span>
  </p>
  <div className='register-sign-in'>
              <Link to="/">
                <Button className="register_btn" type="submit">
                 Login
                </Button>
              </Link>
              <Button className="register_btn_sign_in_google" disabled={!termRead} onClick={() => handleOnClick()}>
              <img src={googleLogo} className="googlelogo" /> Sign in with Google
                </Button>
            </div>
  <div className="d-flex justify-content-center mt-2">
    <div>
      Copyright &copy;{' '}
      <a href="https://datatera.ai/" target="_blank" rel="noreferrer">
        Datatera.ai
      </a>
    </div>
    <div className="ms-2">
      <a
        href="https://datatera.ai/terms/"
        target="_blank"
        rel="noreferrer"
      >
        Terms of use
      </a>
    </div>
    <div className="ms-2">
      <a
        href="https://datatera.ai/privacypolicy/"
        target="_blank"
        rel="noreferrer"
      >
        Privacy policy
      </a>
    </div>
  </div>
</div>

        </div>
      </div>
    </div>
    </AuthLayout>
  
  );
};

export default Signup;
