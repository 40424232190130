import { Box } from "@mui/material";
import ColumnEditorCsv from "./ColumnEditorCsv";

export default function EditColumnClassifier({selectedColumn ,setSelectedColumn, setConnectedClassifier,setFileName,updateColumn, editedColumn,setEditedColumn, setEditingColumnIndex}) {
  return (
    <Box sx={{ minHeight: "100vh", bgcolor: "grey.100", p: 4 }}>
      <ColumnEditorCsv selectedColumn={selectedColumn} setSelectedColumn={setSelectedColumn} setConnectedClassifier={setConnectedClassifier} setFileName={setFileName} updateColumn={updateColumn} editedColumn={editedColumn} setEditingColumnIndex={setEditingColumnIndex} setEditedColumn={setEditedColumn} />
    </Box>
  );
}
