import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import './AddModal.css';
import { Checkbox, FormControlLabel, styled } from '@mui/material';

const AddColumnModal = (props) => {
  const { show, handleClose, handleSave, appendColumn, columnName, loading } =
    props;
  // const { width } = useWindowDimensions();
  const CustomCheckbox = styled(Checkbox)`
    color: green; /* Replace with your desired shade of green */
    &:focus {
      background-color: green; /* Replace with your desired focus color */
    }
    &.Mui-checked {
      color: green; /* Replace with your desired shade of green */
    }
  `;
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [unique, setUnique] = useState(false);
  const [mandatory, setMandatory] = useState(false);
  const [computeField, setComputeField] = useState(false);
  const [datatype, setDatatype] = useState('');

  useEffect(() => {
    if (show) {
      setTitle('');
      setDescription('');
      setUnique(false);
      setMandatory(false);
      setComputeField(false);
      setDatatype('');
    }
  }, [show]);

  return (
    <Modal show={show} size={'md'} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="conversionTitle" style={{ fontSize: '24px' }}>
          Add column {appendColumn ? 'right' : 'left'} to '{columnName}'
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-2">
          <b>Column Title</b>
        </p>
        <input
          className="p-2 rounded input w-100"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        ></input>
        <p className="mb-2">
          <b>Column Description</b>
        </p>
        <textarea
          className="p-2 rounded input w-100"
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div className="d-flex gap-3 flex-wrap">
          <FormControlLabel
            className="d-flex align-items-center"
            control={
              <CustomCheckbox
                id="Unique"
                defaultChecked={unique}
                onChange={(e) => {
                  setUnique(e.target.checked);
                }}
              />
            }
            label="Unique"
          />
          <FormControlLabel
            className="d-flex align-items-center"
            control={
              <CustomCheckbox
                id="Mandatory"
                defaultChecked={mandatory}
                onChange={(e) => {
                  setMandatory(e.target.checked);
                }}
              />
            }
            label="Mandatory"
          />
          <FormControlLabel
            className="d-flex align-items-center"
            control={
              <CustomCheckbox
                id="ComputeField"
                defaultChecked={computeField}
                onChange={(e) => {
                  setComputeField(e.target.checked);
                }}
              />
            }
            label="Compute Field"
          />
        </div>
        <div className="d-flex flex-column">
          <p className="mb-2">
            <b>Select Data Type: </b>
          </p>
          <select
            className="rounded input p-2"
            id="datatype-select"
            value={datatype}
            onChange={(e) => setDatatype(e.target.value)}
          >
            <option value="">Select type</option>
            <option value="text">Text</option>
            <option value="number">Number</option>
            <option value="datetime">Datetime</option>
            <option value="boolean">Boolean</option>
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-save border-0"
          onClick={() =>
            handleSave({
              title,
              description,
              unique,
              mandatory,
              computeField,
              datatype,
            })
          }
          disabled={loading || !title.trim()}
        >
          Add
          {loading && (
            <Spinner
              size="sm"
              animation="border"
              variant="white"
              className="ms-2"
            />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddColumnModal;
